<template>
  <div class="pbf grid_width">
 
<br>
    <GeneralSearchTags 
      :tags="props.intags" 
      :tagsselected="tagsselected" 
      :tagsdefault="props.intags" 
      :tagscount="product_data?.items_tag"    
      @choose="chooseTags"
      @unchoose="unchooseTags"
      />
 


    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :align="'right'"
        :options="[
          {id:'harvests_desc', name: $t('universal_sort_harvests'), icon: 'desc'},      
          {id:'harvests_asc', name: $t('universal_sort_harvests'), icon: 'asc'},
          {id:'reviews_desc', name: $t('universal_sort_reviews'), icon: 'desc'},       
          {id:'reviews_asc', name: $t('universal_sort_reviews'), icon: 'asc'},    
          {id:'az_desc', name: $t('universal_sort_az'), icon: 'desc'},        
          {id:'az_asc', name: $t('universal_sort_az'), icon: 'asc'},   
          {id:'gplant_desc', name: $t('universal_sort_gplant'), icon: 'desc'},           
          {id:'gplant_asc', name: $t('universal_sort_gplant'), icon: 'asc'},
          {id:'diaries_desc', name: $t('universal_sort_diaries'), icon: 'desc'},                   
          {id:'diaries_asc', name: $t('universal_sort_diaries'), icon: 'asc'},
        ]"
        />
    </div>

      
    <div class="report_boxs reports_grid">

      <SkPhotos 
        v-if="is_loading && start == 0"
        />

      <template v-else v-for="pro in product_data?.items">
        <BrandProductItem
          :nm="pro.name"
          :nmalt="pro.name"
          :avatar="pro.cover_s"
          :link="pro.link"
          :rate="pro.item_stat.avg_rate"
          :rateof="props.rateof"
          :cnt_reviews="pro.cnt_reviews"
          :cnt_harvests="pro.cnt_harvests"
          :cnt_diaries="pro.cnt_diaries"
          :cnt_list="pro.cnt_list"
          />
      </template>

      <ClientOnly>        
        <UiInfinityScroll 
          v-if="!isEnded"
          :is-loading="is_loading"
          ref="elInfinityScroll"/>
      </ClientOnly>

    </div>
  

  </div>
</template>

<script setup lang="ts">

import { getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const isEnded = ref(false)
const {$api} = useNuxtApp()
const elInfinityScroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: true
  },
  brandId: {
    type: Number,
    required: true
  },
  category: {
    type: String,
    required: false,
    default: ''
  },
  rateof: {
    type: Number,
    required: false,
    default: 10
  },
  strainId: {
    type: Number,
    required: false
  }
})

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('harvests_desc');
const tagscount = ref([]);
const tagsselected = ref([]);
const tagspermanent = ref([]);
const tagsdefault = ref(null);
const list = ref([]);


if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}

if(props.brandId && (useAuth().isTester(props.brandId) || useAuth().getAccess('view_extra'))){
}else{
  tagspermanent.value.push('not_tests');
}


//tagspermanent.value.push('not_tests');

 
const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){       
  tagsselected.value.push(selected);      
  console.log('chooseTags tagsselected', tagsselected.value);
  clearPagination();
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}



const loadData = async function() {     
  const response = await $api.getProducts({
    // brand_id: props.brandId,
    // category: props.category,
    start: start.value,
    limit: limit.value,
    q: getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: product_data } = await useLazyAsyncData('product_data_' + props.brandId, async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0 && product_data.value){
    product_data.value.items = [];
    isEnded.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.items?.length == 0)
    isEnded.value = true;  

  product_data.value.items = [...product_data.value.items, ...dt.items]
  product_data.value.items_tag = dt.items_tag
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}
.sortb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}


</style>
